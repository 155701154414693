<script setup lang="ts">
import { LeftArrowIcon, RightArrowIcon } from '@glow/shared/components/icons';
import AppointmentsBlockItem from './AppointmentsBlockItem.vue';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { breakpoints } from '@glow/shared/lib/breakpoints';
import { useQuery } from '@urql/vue';
import { graphql } from '@/gql';

type AppointmentListTab = 'past' | 'upcoming';
const selectedTab = ref<AppointmentListTab>('upcoming');

function getTabClasses(tab: AppointmentListTab) {
  return [
    'cursor-pointer',
    'transition-md',
    selectedTab.value === tab ? 'text-black-primary' : 'text-black-tertiary',
  ];
}

const isLoggedIn = useIsLoggedIn();
const { data: queryData } = useQuery({
  query: graphql(/* GraphQL */ `
    query AppointmentsBlock_AppointmentsQuery(
      $startDate: DateTime!
      $endDate: DateTime!
    ) {
      appointments(startDate: $startDate, endDate: $endDate) {
        id
        ...AppointmentsBlockItem_AppointmentFragment
      }
    }
  `),
  variables: {
    startDate: 0,
    endDate: 10000000000000,
  },
  pause: toRef(() => !isLoggedIn.value),
});
const appointments = toRef(() => queryData.value?.appointments);
</script>

<template>
  <section v-if="appointments?.length" class="records section">
    <div class="head">
      <div class="head__items">
        <h2
          :class="getTabClasses('upcoming')"
          @click="selectedTab = 'upcoming'"
        >
          Предстоящие записи
        </h2>
        <h2 :class="getTabClasses('past')" @click="selectedTab = 'past'">
          Прошлые записи
        </h2>
      </div>

      <div class="records__slider-buttons d-none d-md-block">
        <button class="records__slider-previous-button">
          <LeftArrowIcon />
        </button>
        <button class="records__slider-next-button">
          <RightArrowIcon />
        </button>
      </div>
    </div>

    <Swiper
      :modules="[Navigation]"
      :navigation="{
        prevEl: '.records__slider-previous-button',
        nextEl: '.records__slider-next-button',
      }"
      :space-between="20"
      slides-per-view="auto"
      :breakpoints="{
        [breakpoints.xs]: {
          spaceBetween: 8,
        },
        [breakpoints.md]: {
          slidesPerView: 2,
        },
      }"
    >
      <SwiperSlide
        v-for="appointment in appointments"
        :key="appointment.id"
        class="h-auto"
      >
        <AppointmentsBlockItem class="h-100" :appointment="appointment" />
      </SwiperSlide>
    </Swiper>
  </section>
</template>

<style lang="scss" scoped>
.records {
  position: relative;

  &__slider-buttons {
    display: flex;
    gap: 10px;
    margin-left: auto;
  }

  &__slider-previous-button,
  &__slider-next-button {
    position: absolute;
    top: 65%;
    z-index: 2;
    padding: 10px;
    color: var(--app-color-black);
    background: var(--app-color-white);
    border: none;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0px 0px 16px 0px rgba(20, 20, 20, 0.16);
    transform: translateY(-50%);

    svg {
      width: 28px;
      height: 28px;
    }
  }

  &__slider-previous-button {
    left: -36px;
  }

  &__slider-next-button {
    right: -36px;
    transform: translateY(-50%);
  }
}

.head {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  @media (max-width: 1300px) {
    margin-bottom: 15px;
  }

  &__items {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    gap: 24px;
    white-space: nowrap;
  }
}

.swiper-button-disabled {
  display: none;
}
</style>
