<script setup lang="ts">
import { LeftArrowIcon, RightArrowIcon } from '@glow/shared/components/icons';
import RecentBlogPostsBlockItem from './RecentBlogPostsBlockItem.vue';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import HomeBlockHeader from './HomeBlockHeader.vue';
import { breakpoints } from '@glow/shared/lib/breakpoints';
import { useQuery } from '@urql/vue';
import { graphql } from '@/gql';
import { useRoutes } from '@glow/shared/lib/routes';

const { getUrl } = useRoutes();
const { data: queryData } = useQuery({
  query: graphql(/* GraphQL */ `
    query RecentBlogPostsBlock_BlogPostsQuery {
      blogPosts {
        id
        ...RecentBlogPostsBlock_BlogPostFragment
      }
    }
  `),
});
const blogPosts = toRef(() => queryData.value?.blogPosts);
</script>

<template>
  <section v-if="blogPosts?.length" class="posts section">
    <HomeBlockHeader title="Мы пишем" :more-link="getUrl('blogPosts')" />

    <div class="posts__info">
      <div class="posts__slider-buttons">
        <button class="posts__slider-previous-button">
          <LeftArrowIcon />
        </button>
        <button class="posts__slider-next-button">
          <RightArrowIcon />
        </button>
      </div>
    </div>

    <Swiper
      :modules="[Navigation]"
      :navigation="{
        prevEl: '.posts__slider-previous-button',
        nextEl: '.posts__slider-next-button',
      }"
      :space-between="20"
      slides-per-view="auto"
      :breakpoints="{
        [breakpoints.xs]: {
          slidesPerView: 1.25,
        },
        [breakpoints.md]: {
          slidesPerView: 2,
        },
      }"
    >
      <SwiperSlide
        v-for="blogPost in blogPosts"
        :key="blogPost.id"
        class="h-auto"
      >
        <RecentBlogPostsBlockItem
          class="page-card p-4 h-100"
          :blog-post="blogPost"
        />
      </SwiperSlide>
    </Swiper>
  </section>
</template>

<style lang="scss" scoped>
.title {
  font-weight: 700;
  font-size: stretch-size(375, 1920, 24, 32);
}

.posts {
  position: relative;

  &__info {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  &__slider-buttons {
    display: flex;
    gap: 10px;
    margin-left: auto;

    @media (max-width: 480px) {
      display: none;
    }
  }

  &__slider-previous-button,
  &__slider-next-button {
    position: absolute;
    top: 65%;
    z-index: 2;
    padding: 10px;
    color: var(--app-color-black);
    background: var(--app-color-white);
    border: none;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0px 0px 16px 0px rgba(20, 20, 20, 0.16);
    transform: translateY(-50%);

    svg {
      width: 28px;
      height: 28px;
    }
  }

  &__slider-previous-button {
    left: -36px;
  }

  &__slider-next-button {
    right: -36px;
  }
}

.swiper-button-disabled {
  display: none;
}

.slider-button-image {
  width: 10px;
  height: 18px;

  @media (max-width: 1300px) {
    width: 8px;
    height: 16px;
  }
}
</style>
