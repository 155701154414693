<script setup lang="ts">
import AppointmentsBlock from '../components/AppointmentsBlock.vue';
import NearbyMastersBlock from '../components/NearbyMastersBlock.vue';
import DownloadAppBlock from '../components/DownloadAppBlock.vue';
import BecomeMasterBlock from '../components/BecomeMasterBlock.vue';
import CrmLinkBlock from '../components/CrmLinkBlock.vue';
import SpecializationsBlock from '../components/SpecializationsBlock.vue';
import PopularServiceMastersBlock from '../components/PopularServiceMastersBlock.vue';
import FeaturedServicesBlock from '../components/FeaturedServicesBlock.vue';
import RecentBlogPostsBlock from '../components/RecentBlogPostsBlock.vue';
import { CartBlock } from '@/features/cart';
import HomePartnerMap from '../components/HomePartnerMap.vue';
import AddressSelectBlock from '../components/AddressSelectBlock.vue';
import AskQuestionBlock from '@/layouts/app/AskQuestionBlock.vue';
import LandingLinkBlock from '../components/LandingLinkBlock.vue';
import { useAddressStore } from '@/stores/address';
import { useAppLayoutBlockVisibilityStore } from '@/stores/appLayoutBlockVisibility';

useHead({ title: 'Главная' });

const blockVisibility = useAppLayoutBlockVisibilityStore();
const isMobileApp = useIsMobileApp();
const addressStore = useAddressStore();
</script>

<template>
  <div>
    <NuxtLayout name="app">
      <template #sidebar>
        <CrmLinkBlock
          v-if="blockVisibility.isCrmLinkBlockVisible"
          @close="blockVisibility.isCrmLinkBlockVisible = false"
        />
        <CartBlock />
      </template>
      <div class="home">
        <AddressSelectBlock v-if="!addressStore.address" />
        <AppointmentsBlock />
        <NearbyMastersBlock />
        <HomePartnerMap />
        <AskQuestionBlock
          v-if="blockVisibility.isAskQuestionBlockVisible"
          class="d-block d-md-none"
          @close="blockVisibility.isAskQuestionBlockVisible = false"
        />
        <SpecializationsBlock />
        <CrmLinkBlock
          v-if="blockVisibility.isCrmLinkBlockVisible"
          class="d-block d-md-none"
          @close="blockVisibility.isCrmLinkBlockVisible = false"
        />
        <!-- <PopularServiceMastersBlock /> -->
        <LandingLinkBlock v-if="isMobileApp" />
        <DownloadAppBlock
          v-else-if="blockVisibility.isMobileAppBlockVisible"
          @close="blockVisibility.isMobileAppBlockVisible = false"
        />
        <!-- <FeaturedServicesBlock /> -->
        <RecentBlogPostsBlock />
        <BecomeMasterBlock
          v-if="blockVisibility.isBecomeMasterBlockVisible"
          @close="blockVisibility.isBecomeMasterBlockVisible = false"
        />
      </div>
    </NuxtLayout>
  </div>
</template>

<style scoped lang="scss">
.home {
  display: flex;
  flex-direction: column;
  gap: 60px;
}
</style>
