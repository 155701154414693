<script setup lang="ts">
import { MinusIcon, NearIcon, PlusIcon } from '@glow/shared/components/icons';
import { refDebounced } from '@vueuse/shared';
import {
  type MapMarker,
  MapMarkerLayer,
  renderMarkerWithImage,
  useMap,
} from '@glow/shared/lib/ol';
import { useBodyScrolling } from '@glow/shared/store/bodyScrolling';
import MasterPreview from './MasterPreview.vue';
import { useQuery } from '@urql/vue';
import { graphql } from '@/gql';
import { useRoutes } from '@glow/shared/lib/routes';
import { DEBOUNCE_INTERVAL_MS } from '@glow/shared/lib/constants';

const { getUrl } = useRoutes();
const { disableBodyScrolling, enableBodyScrolling } = useBodyScrolling();
const isExpanded = ref(false);
watch(isExpanded, (value) => {
  window.scrollTo({ top: 0 });
  if (value) disableBodyScrolling();
  else enableBodyScrolling();
});

const mapBounds = ref<number[]>();
const filters = computed(() => ({
  address: 'Кремль',
  bounds: mapBounds.value,
}));
const filtersDebounced = refDebounced(filters, DEBOUNCE_INTERVAL_MS);
const { data: queryData } = useQuery({
  query: graphql(/* GraphQL */ `
    query HomePartnerMap_MastersQuery {
      masters {
        data {
          id
          workPlaces {
            id
            address
            location
            city {
              id
              slug
            }
          }
          ...MasterPreview_MasterFragment
        }
      }
    }
  `),
  variables: filtersDebounced,
});
const masters = toRef(() => queryData.value?.masters);

type SelectedMasterId = {
  masterId: number;
  workPlaceId: number;
};

const selectedMasterId = ref<SelectedMasterId>();
const selectedMasterData = computed(() => {
  if (!selectedMasterId.value) return;
  const master = masters.value?.data?.find(
    (master) => master.id === selectedMasterId.value?.masterId
  );
  const workPlace = master?.workPlaces.find(
    (workPlace) => workPlace.id === selectedMasterId.value?.workPlaceId
  );
  return {
    master,
    workPlace,
  };
});
const selectedMasterLink = computed(() =>
  getUrl('partnerDetails', {
    type: 'master',
    partner: selectedMasterData.value?.master.slug,
    city: selectedMasterData.value?.workPlace.city.slug,
  })
);
const mapElementRef = ref<HTMLElement>();
const mapEvents = reactive<{
  onExpand?: () => void;
  onZoomIn?: () => void;
  onZoomChange?: (delta: 1 | -1) => void;
  onSearchNear?: () => void;
}>({});

const detectGeolocation = useDetectGeolocation();
useMap(mapElementRef, {
  onReady: (map) => {
    const markerLayer = new MapMarkerLayer(map, {
      renderer: (marker) =>
        renderMarkerWithImage(
          (marker.properties?.avatarUrl as string) ?? '',
          (marker.properties?.name as string)?.[0] ?? '?'
        ),
      onMarkerClick: (marker) => {
        if (!marker.properties) return;
        const { id, workPlaceId } = marker.properties;
        selectedMasterId.value = {
          masterId: id as number,
          workPlaceId: workPlaceId as number,
        };
        // navigateTo({
        //   name: 'Partner details',
        //   params: {
        //     type: pluralizePartnerType(partnerType as PartnerType),
        //     partner: slug as string,
        //     city: citySlug as string,
        //   },
        // });
      },
    });
    // watch(
    //   meta,
    //   (meta) => {
    //     if (!meta) return;
    //     map.center = meta.center;
    //   },
    //   { immediate: true }
    // );

    map.olMap.on('moveend', () => {
      mapBounds.value = map.extent;
    });

    watch(masters, (data) => {
      if (!data) return;

      const markers: MapMarker[] = [];
      for (const master of data.data ?? [])
        for (const workPlace of master?.workPlaces ?? [])
          markers.push({
            coords: workPlace.location,
            properties: {
              id: master.id,
              name: master.fullName,
              slug: master.slug,
              avatarUrl: master.avatarUrl,
              partnerType: master.fullName,
              workPlaceId: workPlace.id,
              citySlug: workPlace.city.slug,
            },
          });

      markerLayer.setMarkers(markers);
    });

    mapEvents.onZoomChange = (delta) => {
      map.changeZoom(delta);
    };
    mapEvents.onSearchNear = async () => {
      const { coords } = await detectGeolocation();
      map.center = [coords.latitude, coords.longitude];
    };
  },
});
</script>

<template>
  <div :class="['partner-map', { 'partner-map_expanded': isExpanded }]">
    <div ref="mapElementRef" class="partner-map__map"></div>
    <AppButton
      color="white"
      class="partner-map__expand-button shadow"
      @click="isExpanded = !isExpanded"
    >
      {{ isExpanded ? 'Свернуть' : 'Развернуть' }}
    </AppButton>

    <div class="partner-map__zoom-buttons">
      <AppButton
        :icon-start="PlusIcon"
        border-radius="full"
        variant="secondary"
        color="light"
        class="partner-map__zoom-in-button shadow"
        @click="mapEvents.onZoomChange?.(1)"
      />
      <AppButton
        :icon-start="MinusIcon"
        border-radius="full"
        variant="secondary"
        color="light"
        class="partner-map__zoom-out-button shadow"
        @click="mapEvents.onZoomChange?.(-1)"
      />
    </div>
    <AppButton
      v-if="isExpanded"
      :icon-start="NearIcon"
      border-radius="full"
      variant="secondary"
      color="light"
      class="partner-map__near-button shadow"
      @click="mapEvents.onSearchNear?.()"
    />
    <div
      v-if="selectedMasterData"
      class="partner-map__partner-preview shadow d-none d-md-block"
    >
      <MasterPreview :master="selectedMasterData.master" />
      <AppButton :to="selectedMasterLink" fluid variant="link">
        Подробнее
      </AppButton>
    </div>
  </div>
</template>

<style scoped lang="scss">
.partner-map {
  height: stretch-size(375, 1920, 200, 320);
  position: relative;
  border-radius: 16px;
  overflow: hidden;

  &_expanded {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    border-radius: 0;
  }

  &__map {
    width: 100%;
    height: 100%;
  }

  &__expand-button {
    position: absolute;
    left: 8px;
    bottom: 8px;
    z-index: 7;
    padding: 0.7em 2em;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: var(--app-color-primary) !important;

    @include media-breakpoint-down(md) {
      left: 16px;
      bottom: 16px;
    }
  }

  &__zoom-buttons {
    position: absolute;
    top: 8px;
    left: 8px;
    z-index: 7;
    display: flex;
    justify-content: flex-end;
    gap: 8px;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &_expanded &__zoom-buttons {
    bottom: unset;
    top: 50%;
    transform: translateY(-50%);
    flex-direction: column;
  }

  &_expanded &__expand-button {
    @include media-breakpoint-down(lg) {
      top: var(--layout-padding-top);
      bottom: unset;
    }
  }

  &__zoom-in-button,
  &__zoom-out-button {
    padding: 0.7em;
    font-size: 15px;
  }

  &__near-button {
    display: block;
    position: absolute;
    top: 26px;
    right: 26px;
    padding: 0.7em;
    z-index: 7;

    @include media-breakpoint-down(md) {
      top: var(--layout-padding-top);
    }
  }

  &__partner-preview {
    position: absolute;
    top: 8px;
    right: 8px;
    bottom: 8px;
    width: 30%;
    background: var(--app-color-white);
    border-radius: 12px;
    padding: 12px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
</style>
