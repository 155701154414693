<script setup lang="ts">
import { LocationIcon } from '@glow/shared/components/icons';
import { formatDate } from '@glow/shared/utils/format';
import { type FragmentType, graphql } from '@/gql';

const appointmentFragment = graphql(/* GraphQL */ `
  fragment AppointmentsBlockItem_AppointmentFragment on Appointment {
    id
    startedAt
    endedAt
    workPlace {
      id
      address
      master {
        id
        fullName
        avatarUrl
        slug
      }
      company {
        id
        address
        name
      }
    }
    services {
      id
      service {
        id
        name
      }
    }
  }
`);
const props = defineProps<{
  appointment: FragmentType<typeof appointmentFragment>;
}>();

const appointment = useFragmentData(
  appointmentFragment,
  () => props.appointment
);
const subject = computed(() =>
  appointment.value.services.map((service) => service.service.name).join(', ')
);
</script>

<template>
  <div class="page-card card">
    <div class="card__head">
      <div>
        <span class="card__date">
          Запись на {{ formatDate(appointment.startedAt) }}
        </span>
        <span class="card__subject">{{ subject }}</span>
      </div>
      <AppAvatar
        class="card__avatar"
        size="xs"
        :name="appointment.workPlace.master.fullName"
        :src="appointment.workPlace.master.avatarUrl"
      />
    </div>

    <div class="card__fullname">
      {{ appointment.workPlace.master.fullName }}
    </div>
    <div class="card__location">
      <LocationIcon class="card__location-icon" />
      <div>
        <span class="card__location-name">
          {{ appointment.workPlace.company?.name ?? 'Личное рабочее место' }}
        </span>
        <address class="card__location-address">
          {{
            appointment.workPlace.company?.address ??
            appointment.workPlace.address
          }}
        </address>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.card {
  border-radius: 8px;
  padding: 16px;
  font-family: 'Nunito Sans';
  font-weight: 400;

  &__head {
    display: flex;
    justify-content: space-between;
  }

  &__date {
    display: block;
  }

  &__avatar {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    margin-left: 16px;
  }

  &__date {
    font-weight: 700;
    line-height: 22px;
  }

  &__subject {
    line-height: 24px;
  }

  &__fullname {
    font-family: 'Nunito Sans';
    font-weight: 700;
    margin: 8px 0;
  }

  &__location {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
  }

  &__location-icon {
    color: var(--app-color-primary);
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

  &__location-address {
    font-style: normal;
    color: var(--app-color-black-tertiary);
  }
}
</style>
