<script setup lang="ts">
import { CloseIcon } from '@glow/shared/components/icons';
import DownloadAppModal from './DownloadAppModal.vue';
import { mobileAppLinks } from '@glow/shared/lib/constants';

const emit = defineEmits<{
  (e: 'close'): void;
}>();

const { staticUrl } = useRuntimeConfig().public;
const downloadBackgroundUrl = toRef(
  () => `url(${staticUrl}/images/home/download-background.png)`
);
const downloadBackgroundMobileUrl = toRef(
  () => `url(${staticUrl}/images/home/download-background-mobile.png)`
);
const isModalVisible = ref(false);

const os = useOperatingSystem();
function onAppDownload() {
  switch (os.value) {
    case 'android':
      navigateTo(mobileAppLinks.googlePlay);
      break;
    case 'ios':
      navigateTo(mobileAppLinks.appStore);
      break;
    default:
      isModalVisible.value = true;
      break;
  }
}
</script>

<template>
  <section class="download-app section">
    <div class="download-app__content">
      <h2 class="download-app__title">Скачивайте наше мобильное приложение</h2>
      <p class="download-app__description">
        Все самое нужное, всегда под рукой
      </p>
    </div>
    <AppButton
      class="download-app__button"
      color="white"
      @click="onAppDownload"
    >
      Скачать приложение
    </AppButton>

    <AppButton
      class="download-app__close-button shadow"
      color="light"
      border-radius="full"
      size="xs"
      :icon-start="CloseIcon"
      icon-start-color="dark"
      @click="emit('close')"
    />
    <DownloadAppModal v-model="isModalVisible" />
  </section>
</template>

<style lang="scss" scoped>
.download-app {
  border-radius: 24px;
  background: linear-gradient(91deg, #a493d8 0%, #5f72e9 100%);
  padding: 52px 32px;
  position: relative;

  @include media-breakpoint-down(md) {
    padding: 24px 16px;
  }

  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    height: 252px;
    width: 364px;
    background: v-bind(downloadBackgroundUrl) 0 0 / cover no-repeat;

    @include media-breakpoint-down(xxl) {
      right: 0;
      width: 195px;
      height: 243px;
      background: v-bind(downloadBackgroundMobileUrl) 0 0 / cover no-repeat;
    }
  }

  &__content {
    max-width: 415px;
    position: relative;
    z-index: 1;

    @include media-breakpoint-down(lg) {
      max-width: 350px;
    }

    @include media-breakpoint-down(md) {
      max-width: 250px;
    }
  }

  &__title {
    color: #fff;
    font-weight: 700;
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 8px;

    @include media-breakpoint-down(lg) {
      font-size: 28px;
      line-height: 32px;
    }

    @include media-breakpoint-down(md) {
      font-size: 24px;
      line-height: 27px;
    }
  }

  &__description {
    color: #fff;
    font-family: 'Nunito Sans';
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 24px;

    @include media-breakpoint-down(md) {
      max-width: 160px;
      font-size: 15px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  &__button {
    @include media-breakpoint-down(md) {
      margin-top: 120px;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
      position: relative;
      z-index: 1;
    }
  }

  &__close-button {
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 1;
  }
}
</style>
