<script setup lang="ts">
import { LeftArrowIcon, RightArrowIcon } from '@glow/shared/components/icons';
import MasterPreview from './MasterPreview.vue';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import HomeBlockHeader from './HomeBlockHeader.vue';
import { breakpoints } from '@glow/shared/lib/breakpoints';
import { graphql } from '@/gql';
import { useQuery } from '@urql/vue';
import { useAddressStore } from '@/stores/address';
import { useRoutes } from '@glow/shared/lib/routes';

const { getUrl } = useRoutes();
const addressStore = useAddressStore();
const { data: queryData } = useQuery({
  query: graphql(/* GraphQL */ `
    query NearbyMastersBlock_MastersQuery($address: String) {
      masters(address: $address) {
        data {
          id
          ...MasterPreview_MasterFragment
        }
      }
    }
  `),
  variables: toRef(() => ({ address: addressStore.address })),
});
const masters = toRef(() => queryData.value?.masters?.data);
const moreLink = getUrl('partnerCatalog', {
  city: 'moscow',
  type: 'master',
  address: addressStore.address,
  radius: 100,
});
</script>

<template>
  <section v-if="masters?.length" class="nearby-masters section">
    <HomeBlockHeader title="Мастера рядом" :more-link="moreLink" />

    <div class="nearby-masters__info">
      <div class="nearby-masters__address-box">
        <template v-if="addressStore.address">
          <span class="nearby-masters__address-label">Рядом с адресом:</span>
          <address class="nearby-masters__address d-inline text-primary">
            {{ addressStore.address }}
          </address>
        </template>
        <template v-else>
          Укажите адрес, чтобы увеличить точность поиска мастеров поблизовсти
        </template>
      </div>

      <div class="nearby-masters__slider-buttons">
        <button class="nearby-masters__slider-previous-button">
          <LeftArrowIcon />
        </button>
        <button class="nearby-masters__slider-next-button">
          <RightArrowIcon />
        </button>
      </div>
    </div>

    <Swiper
      :modules="[Navigation]"
      :navigation="{
        prevEl: '.nearby-masters__slider-previous-button',
        nextEl: '.nearby-masters__slider-next-button',
      }"
      :space-between="20"
      auto-height
      slides-per-view="auto"
      :breakpoints="{
        [breakpoints.lg]: {
          slidesPerView: 2,
        },
        [breakpoints.xxl]: {
          slidesPerView: 3,
        },
      }"
    >
      <SwiperSlide v-for="master in masters" :key="master.id">
        <MasterPreview class="page-card p-4" :master="master" />
      </SwiperSlide>
    </Swiper>
  </section>
</template>

<style lang="scss" scoped>
.nearby-masters {
  position: relative;

  &__info {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  &__address-box {
    display: flex;

    @media (max-width: 640px) {
      display: block;
    }

    @media (max-width: 480px) {
      display: none;
    }
  }

  &__address {
    font-style: normal;
  }

  &__address-label {
    margin-right: 5px;
  }

  .swiper-slide {
    width: 70%;
  }

  &__slider-buttons {
    display: flex;
    gap: 10px;
    margin-left: auto;

    @media (max-width: 480px) {
      display: none;
    }
  }

  &__slider-previous-button,
  &__slider-next-button {
    position: absolute;
    top: 65%;
    z-index: 2;
    padding: 10px;
    color: var(--app-color-black);
    background: var(--app-color-white);
    border: none;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0px 0px 16px 0px rgba(20, 20, 20, 0.16);
    transform: translateY(-50%);

    svg {
      width: 28px;
      height: 28px;
    }
  }

  &__slider-previous-button {
    left: -36px;
  }

  &__slider-next-button {
    right: -36px;
  }
}

.swiper-button-disabled {
  display: none;
}

.slider-button-image {
  width: 10px;
  height: 18px;

  @media (max-width: 1300px) {
    width: 8px;
    height: 16px;
  }
}
</style>
