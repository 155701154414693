<script setup lang="ts">
import { CloseIcon } from '@glow/shared/components/icons';
import { useRoutes } from '@glow/shared/lib/routes';

const emit = defineEmits<{
  (e: 'close'): void;
}>();

const { getUrl } = useRoutes();
const { staticUrl } = useRuntimeConfig().public;
const backgroundUrl = toRef(() => `url(${staticUrl}/images/crm.png)`);
</script>

<template>
  <div class="crm-link">
    <div class="crm-link__content">
      <h3 class="crm-link__title">Вы мастер или салон?</h3>
      <p class="crm-link__description">
        Управляйте рабочим профилем из одного места
      </p>
      <AppButton :to="getUrl('crmHome')" size="sm" border-radius="lg">
        Перейти
      </AppButton>
    </div>
    <AppButton
      class="crm-link__close-button shadow"
      color="light"
      size="xs"
      border-radius="full"
      :icon-start="CloseIcon"
      icon-start-color="dark"
      @click="emit('close')"
    />
  </div>
</template>

<style lang="scss" scoped>
.crm-link {
  display: flex;
  align-items: flex-end;
  border-radius: 16px;
  position: relative;
  overflow: hidden;
  background: var(--app-color-accent-secondary) v-bind(backgroundUrl) 100% 100% /
    25% no-repeat;

  &__content {
    max-width: 75%;
    padding: 15px 20px;
    padding-right: 10px;
  }

  &__title {
    margin-bottom: 4px;
    color: var(--app-color-black);
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  &__description {
    font-weight: 'Nunito Sans';
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }

  &__close-button {
    position: absolute;
    top: 12px;
    right: 12px;
  }
}
</style>
