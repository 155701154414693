<script setup lang="ts">
import type { RouteLocationRaw } from 'vue-router';

const props = defineProps<{
  title: string;
  moreLink?: RouteLocationRaw;
}>();
</script>

<template>
  <div class="head">
    <h2>{{ props.title }}</h2>
    <NuxtLink :to="props.moreLink" class="watch-all-link">
      смотреть все
    </NuxtLink>
  </div>
</template>

<style scoped lang="scss">
.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  margin-bottom: 8px;
}

.watch-all-link {
  color: var(--app-color-black-tertiary);
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  white-space: nowrap;

  @media (max-width: 992px) {
    font-size: 20px;
    line-height: 23px;
  }

  @media (max-width: 576px) {
    font-size: 16px;
    line-height: 19px;
  }
}
</style>
