<script setup lang="ts">
import { formatDate } from '@glow/shared/utils/format';
import {
  CalendarIcon,
  ViewIcon,
  ChatIcon,
} from '@glow/shared/components/icons';
import { type FragmentType, graphql } from '@/gql';
import { useRoutes } from '@glow/shared/lib/routes';
import { PlaceholderableImg } from '@glow/shared/components/misc';

const { getUrl } = useRoutes();
const blogPostFragment = graphql(/* GraphQL */ `
  fragment RecentBlogPostsBlock_BlogPostFragment on BlogPost {
    id
    slug
    createdAt
    title
    coverUrl
    shortText
    viewCount
    likeCount
    commentCount
  }
`);

const props = defineProps<{
  blogPost: FragmentType<typeof blogPostFragment>;
}>();
const blogPost = useFragmentData(blogPostFragment, () => props.blogPost);
</script>

<template>
  <NuxtLink :to="getUrl('blogPost', { slug: blogPost.slug })" class="preview">
    <PlaceholderableImg
      class="preview__cover rounded-sm"
      :src="blogPost.coverUrl ?? undefined"
    />

    <div class="preview__meta">
      <div class="d-flex align-items-center gap-1">
        <CalendarIcon class="preview__icon" />
        {{ formatDate(blogPost.createdAt, { representation: 'date' }) }}
      </div>
      <div class="d-flex align-items-center gap-1">
        <ViewIcon class="preview__icon" />
        {{ blogPost.viewCount }}
      </div>
      <div class="d-flex align-items-center gap-1">
        <ChatIcon class="preview__icon" />
        {{ blogPost.commentCount }}
      </div>
    </div>

    <div class="preview__content">
      <div class="preview__title">
        {{ blogPost.title }}
      </div>
      <div class="preview__short-text">
        {{ blogPost.shortText }}
      </div>
    </div>
  </NuxtLink>
</template>

<style scoped lang="scss">
.preview {
  display: flex;
  flex-direction: column;
  gap: var(--app-spacing-3);

  &__cover {
    aspect-ratio: 16 / 9;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: var(--app-spacing-2);
  }

  &__title {
    color: var(--app-color-black);
    font-size: 16px;
    font-weight: 700;
  }

  &__short-text {
    font-size: 15px;
    font-weight: 400;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }

  &__meta {
    color: var(--app-color-black-secondary);
    display: flex;
    flex-wrap: nowrap;
    gap: var(--app-spacing-3);
  }

  &__icon {
    width: 18px;
    height: 18px;
  }
}
</style>
