<script setup lang="ts">
import { formatYearCount } from '@glow/shared/utils/format';
import { formatReviewCount } from '@/features/reviews';
import { formatServiceCount } from '@/features/services';
import { type FragmentType, graphql, type DocumentType } from '@/gql';
import { useRoutes } from '@glow/shared/lib/routes';
import { useAuthModalStore } from '@glow/shared/features/auth';

const { getUrl } = useRoutes();
const COLLAPSED_SERVICE_COUNT = 3;
const masterFragment = graphql(/* GraphQL */ `
  fragment MasterPreview_MasterFragment on Master {
    id
    slug
    fullName
    avatarUrl
    isLiked
    reviewCount
    rating
    services {
      id
      service {
        id
        name
        specialization {
          id
        }
      }
    }
    specializations {
      id
      yearsOfExperience
      specialization {
        id
      }
    }
  }
`);

const props = defineProps<{
  master: FragmentType<typeof masterFragment>;
  serviceId?: number;
}>();

const { executeMutation: updateLikedState } = useMutation(
  graphql(/* GraphQL */ `
    mutation MasterPreview_UpdateMasterLikedStateMutation(
      $id: Int!
      $newIsLiked: Boolean!
    ) {
      updateMasterLikedState(id: $id, newIsLiked: $newIsLiked) {
        id
        isLiked
      }
    }
  `)
);
export type MasterFragmentType = DocumentType<typeof masterFragment>;

const master = useFragmentData(masterFragment, () => props.master);
const {
  value: services,
  collapsedLength: moreServiceCount,
  toggleCollapseState: toggleServiceCollapseState,
  isCollapsed: servicesCollapsed,
} = useCollapsibleArray(
  () => master.value?.services ?? [],
  COLLAPSED_SERVICE_COUNT,
  true
);
const { showLikedToast } = useContextToasts();
const authStore = useAuthModalStore();

function onLikedStateUpdate(newIsLiked: boolean) {
  authStore.ensureLoggedIn(async () => {
    await updateLikedState({ id: master.value.id, newIsLiked });
    showLikedToast(newIsLiked);
  });
}

const yearsOfExperience = computed(() => {
  if (!master.value) return undefined;

  const service = master.value.services.find(
    (service) => service.service.id === props.serviceId
  );
  if (!service) {
    return master.value.specializations[0]?.yearsOfExperience;
  }

  const specialization = master.value.specializations.find(
    (specialization) =>
      specialization.specialization.id === service.service.specialization.id
  );
  return specialization?.yearsOfExperience;
});
const link = computed(() =>
  getUrl('partnerDetails', {
    city: 'moscow',
    type: 'master',
    partner: master.value.slug,
  })
);
</script>

<template>
  <div class="card">
    <div class="card__head">
      <NuxtLink class="d-flex flex-nowrap gap-3" :to="link">
        <AppAvatar
          class="card__avatar"
          size="xs"
          :src="master.avatarUrl"
          :name="master.fullName"
        />
        <div class="card__fullname text-black-primary">
          {{ master.fullName }}
        </div>
      </NuxtLink>

      <AppCheckbox
        variant="like"
        class="card__like"
        :model-value="master.isLiked"
        @update:model-value="onLikedStateUpdate"
      />
    </div>
    <div class="card__experience">
      Стаж {{ formatYearCount(yearsOfExperience ?? 0) }}
    </div>
    <div class="card__reviews-box">
      <div class="card__stars">
        <AppRating :model-value="master.rating" />
      </div>
      <div class="card__reviews text-primary">
        ({{ formatReviewCount(master.reviewCount) }})
      </div>
    </div>
    <ul v-if="services.length" class="card__services">
      <li
        v-for="service in services"
        :key="service.id"
        class="card__services-item"
      >
        {{ service.service.name }}
      </li>
      <AppButton
        v-if="moreServiceCount"
        class="card__more-services text-primary"
        variant="link"
        text-align="start"
        @click="toggleServiceCollapseState"
      >
        {{
          servicesCollapsed
            ? `Ещё ${formatServiceCount(moreServiceCount)}`
            : 'Свернуть'
        }}
      </AppButton>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.card {
  &__head {
    display: flex;
  }

  &__avatar {
    height: 36px;
    width: 36px;
    border-radius: 50%;
  }

  &__fullname {
    font-weight: 700;
    max-width: 100px;
  }

  &__like {
    width: 18px;
    height: 17px;
    margin-left: auto;
  }

  &__experience {
    color: var(--app-color-black-secondary);
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    margin-top: 8px;
    margin-bottom: 12px;
  }

  &__reviews-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__stars {
    display: flex;
    gap: 5px;
  }

  &__stars-item {
    width: 18px;
    height: 17px;
    fill: transparent;
  }

  &__stars-item--filled {
    fill: #ffc839;
  }

  &__reviews {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  &__services {
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 0.25em;
    padding-top: 12px;
    margin-top: 12px;
    margin-bottom: 0;
    border-top: 1px solid var(--app-color-white-quaternary);

    li {
      list-style: none;
      position: relative;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-left: 0.75em;
      font-family: Nunito Sans;
      font-size: 14px;
      line-height: 20px;

      &::before {
        content: '';
        position: absolute;
        height: 0.25em;
        width: 0.25em;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        border-radius: 50%;
        background: var(--app-color-black-quaternary);
      }
    }
  }

  &__more-services {
    display: block;
    font-size: 14px;
    font-weight: 400;
  }
}
</style>
